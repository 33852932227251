import React from 'react';
import { useNavigate } from 'react-router';
import PropertyCard from './components/PropertyCard';

const PostsPage = () => {
  const navigate = useNavigate();

  const postsData = [
    {
      id: 1,
      type: 'Sale',
      price: '$350,000',
      location: '123 Main St, Springfield, IL',
      beds: 4,
      baths: 2,
      size: 2500,
      image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
      agent: 'John Doe',
      promoted: true,
    },
    {
      id: 2,
      type: 'Rent',
      price: '$2,000/mo',
      location: '456 Elm St, Springfield, IL',
      beds: 3,
      baths: 2,
      size: 1800,
      image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
      agent: 'Jane Smith',
      promoted: false,
    },
    {
      id: 3,
      type: 'Sale',
      price: '$450,000',
      location: '789 Oak St, Springfield, IL',
      beds: 5,
      baths: 3,
      size: 3000,
      image: `${process.env.PUBLIC_URL}/assets/images/image.png`,
      agent: 'Alice Johnson',
      promoted: false,
    },
  ];


  const createPost = () => {
    navigate('/post'); 
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Posts</h1>
        <button
          onClick={createPost}
          className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition-colors"
        >
          Create Post
        </button>
      </div>

      <div className="space-y-4">
        {postsData.map((post) => (
          <PropertyCard key={post.id} data={post} />
        ))}
      </div>
    </div>
  );
};

export default PostsPage;
