
import React, { useState, useEffect, useRef } from 'react';
import { registerUser } from '../utils/authFunc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/authContext';
import { useNavigate } from 'react-router';


const RegisterPopover = ({ onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  const { setShowPopover } = useAuth();
  const navigate = useNavigate();

  const popoverRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const validatePhoneNumber = (phone) => {
    const regex = /^0\d{9}$/; 
    return regex.test(phone);
  };

  const handleRegister = async () => {
    if (!firstName || !lastName || !phoneNumber) {
      setError('All fields are required');
      return;
    }
    if (!validatePhoneNumber(phoneNumber)) {
      setError('Phone number must be in the format 0742551573');
      return;
    }
    try {
      await registerUser(firstName, lastName, phoneNumber);
      navigate('/login');
      onClose();
    } catch (err) {
      setError('Failed to register. Please try again.');
      console.log('Failed to register. Please try again.')
    }
  };

  return (
    <div
      ref={popoverRef}
      style={{zIndex: 100}}
      className="popover fixed z-100 bg-white shadow-lg rounded-lg p-8 w-[640px] md:w-[768px] max-w-full mx-auto top-1/2 transform -translate-y-1/2"
    >
      <button
        onClick={() => onClose()}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
      >
        <FontAwesomeIcon icon={faTimes} className="text-xl" />
      </button>
      <img
        src="/assets/images/logo.svg"
        alt="Logo"
        className="w-48 h-12 mb-6 mx-auto"
      />
      <h2 className="text-2xl font-semibold mb-6 text-center">Register</h2>
      <input
        type="text"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        className="w-full border border-gray-300 p-3 rounded mb-4"
      />
      <input
        type="text"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        className="w-full border border-gray-300 p-3 rounded mb-4"
      />
      <input
        type="text"
        placeholder="Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        className="w-full border border-gray-300 p-3 rounded mb-4"
      />
      {error && <p className="text-red-500 text-sm mb-4 text-center">{error}</p>}
      <button
        onClick={handleRegister}
        className="bg-yellow-500 text-white py-3 px-6 rounded w-full"
      >
        Register
      </button>
      <p className="mt-6 text-center">
        Already have an account?{' '}
        <button
          onClick={() => setShowPopover('login')}
          className="text-yellow-500"
        >
          Login
        </button>
      </p>
    </div>
  );
};

export default RegisterPopover;
