
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSliders } from '@fortawesome/free-solid-svg-icons';

const Search = ({ placeholder, onSearch }) => {
  return (
    <div className="flex justify-center items-center w-full px-4">
      <div className="flex items-center w-full lg:w-[50%]">
        <div className="flex pl-4 sm:pl-6 md:pl-8 lg:pl-10 items-center bg-white rounded-md flex-grow shadow-md">
          <input
            type="text"
            placeholder={placeholder}
            className="flex-grow p-2 bg-white outline-none text-sm sm:text-base"
            onChange={(e) => onSearch(e.target.value)}
          />
          <div className="bg-yellow-500 h-[50px] w-[60px] sm:h-[55px] sm:w-[65px] md:h-[60px] md:w-[70px] flex items-center justify-center rounded-md">
            <FontAwesomeIcon 
              icon={faSearch} 
              className="text-white" 
              size="lg" 
            />
          </div>
        </div>
        {/* <div className="hidden md:flex bg-yellow-500 h-[50px] w-[50px] sm:h-[55px] sm:w-[55px] md:h-[60px] md:w-[60px] items-center justify-center rounded-md ml-2 sm:ml-3 md:ml-4">
          <FontAwesomeIcon 
            icon={faSliders} 
            className="text-white" 
            style={{ fontSize: '1.5rem' }} 
          />
        </div> */}
      </div>
    </div>
  );
};

export default Search;
