
import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const plans = [
  { 
    duration: "1 Day", 
    price: "Tsh. 2500",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"]
  },
  { 
    duration: "7 Days", 
    price: "Tsh. 14,000",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"]
  },
  { 
    duration: "14 Days", 
    price: "Tsh. 21,000",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"]
  },
  { 
    duration: "30 Days", 
    price: "Tsh. 37,500",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"]
  },
];

const SubscriptionPage = () => {
  const navigate = useNavigate();

  const handleSelectPlan = (plan) => {
    navigate(`/plan/${plan.duration}`, { state: { plan } });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center md:py-12 bg-gray-100 p-8">
    
    {/* <div className="flex  justify-between">
    <button 
        onClick={handleBackClick}
        className="flex items-left text-gray-500 hover:text-gray-900 mb-6 transition duration-200"
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
        Back
      </button>
      
      <h1 className="text-2xl text-center md:text-4xl font-bold mb-4 md:mb-12 lg:mb-12">
        Choose Property Viewing Subscription
      </h1>
    </div> */}
     <button 
    onClick={handleBackClick}
    className="flex items-center mr-80 mb-4 md:hidden md:mr-80 text-gray-500 hover:text-gray-900 transition duration-200"
  >
    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
    Back
  </button>
    <div className="flex items-center justify-between mb-8">
  <button 
    onClick={handleBackClick}
    className="flex items-center hidden md:block md:mr-80 text-gray-500 hover:text-gray-900 transition duration-200"
  >
    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
    Back
  </button>
  
  <h1 className="text-2xl text-center md:text-4xl font-bold flex-1">
    Choose Property Viewing Subscription
  </h1>
</div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {plans.map((plan, index) => (
          <div key={index} className="p-10 w-[340px] md:p-12 bg-white shadow-lg rounded-lg text-center transform hover:scale-105 transition-transform duration-300">
            <h2 className="text-2xl md:text-3xl font-semibold mb-6">{plan.duration}</h2>
            <p className="text-2xl md:text-3xl mb-6">{plan.price}</p>
            <ul className="text-lg md:text-xl text-gray-700 mb-8">
              {plan.features.map((feature, i) => (
                <li key={i} className="mb-2">
                  <FontAwesomeIcon icon={faCheck} className="mr-3" />
                  {feature}
                </li>
              ))}
            </ul>
            <button 
              className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-3 px-6 rounded-full text-lg md:text-xl"
              onClick={() => handleSelectPlan(plan)}
            >
              Select Plan
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPage;
