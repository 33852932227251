
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopBar from '../../components/TopBar';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
        <TopBar/>
      <Header />
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
