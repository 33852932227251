
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faHeart, faCamera, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router';

const HouseForSaleCard = ({ data }) => {
  const typeColor = data.type !== 'Rent' ? 'green' : 'yellow';
  const [isLiked, setIsLiked] = React.useState(false);
  const [isBooked, setIsBooked] = React.useState(false);

  const toggleLike = () => {
    setIsLiked(!isLiked);
  };

  const navigate = useNavigate();

  const goToDetails = () => {
    navigate(`/details/${data.id}`, { state: { data } });
  };


  const toggleBooked = () => {
    setIsBooked(!isBooked);
  };

  return (
    <div 
      className="border-2 z-0 rounded-lg p-3 w-60 md:w-full lg:w-72  xl:w-72 shadow-sm h-full transition-transform duration-300 hover:shadow-lg hover:shadow-gray-400 hover:bg-opacity-90" 
      style={{ width: '100%', zIndex: 0}}
      onClick={goToDetails}
    >
      <div className="relative">
      <img
        src={data?.media?.pictures?.find(picture => picture.name === 'image')?.pictures[0] || '/assets/images/image.png'}
        alt="Property"
        className="w-full h-46 object-cover rounded-lg"
        style={{ height: '200px', objectFit: 'cover' }}
        />
        {/* <img src={data.image} alt="Property" className="w-full h-46 object-cover rounded-lg" /> */}
        <div className="absolute top-2 right-2 bg-gray-400 text-white text-xs rounded-full px-2 py-1 flex items-center">
          <FontAwesomeIcon icon={faCamera} className="mr-1" />
          <span>12</span> 
        </div>
        <div
        className="absolute bottom-2 left-2 right-2 bg-white p-2 flex justify-between items-center rounded-lg transition-colors duration-300 ease-in-out hover:bg-white-600 hover:bg-opacity-800"
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.95)', gap: '8px' }}
        onClick={goToDetails}
      >
        <div className="flex items-center bg-gray-400 w-8 h-8 rounded-full hover:opacity-75">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/user.png`}
            alt="User"
            className="h-full w-full object-cover rounded-full"
          />
        </div>
        <div className="mr-12">
          <span className="text-xs hover:opacity-75">{data.ownerName.length > 15 ? `${data.ownerName.slice(0, 12)}...` : data.ownerName}</span>
        </div>
        <button className="bg-yellow-500 text-black px-2 py-1 text-xs rounded-lg hover:opacity-75">
          View Contacts
        </button>
      </div>

      </div>
      <div className="mt-4">
      <p className="text-xl font-medium" style={{ fontFamily: 'Lato, sans-serif' }}>
        {data?.title?.length > 22 ? `${data.title.slice(0, 22)}...` : data.title || 'No title'}
      </p>
        <div className="flex justify-between items-center mb-2">
          <div className={`text-${typeColor}-500 flex items-center`}>
            <div className={`w-2 h-2 bg-${typeColor}-500 rounded-full mr-2`}></div>
            <p className={`text-${typeColor} text-sm`}>{data.type}</p>
          </div>
          <div className="flex items-center space-x-2">
            <FontAwesomeIcon
              icon={faBook}
              className={`border rounded-full p-1 hover:text-gray-600 cursor-pointer ${isBooked ? 'text-gray-600' : 'text-gray-400'}`}
              onClick={toggleBooked}
            />
            <FontAwesomeIcon
              icon={isLiked ? faHeart : farHeart}
              className={`border rounded-full p-1 hover:text-red-500 cursor-pointer ${isLiked ? 'text-red-500' : 'text-gray-400'}`}
              onClick={toggleLike}
            />
          </div>
        </div>
        <p className="text-md font-bold">{data.housePrice} TZS</p>
        <div className="flex items-center text-xs text-gray-500 mt-2">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
          <p>{data.location}</p>
        </div>
        <div className="flex justify-between items-center mt-2">
          <div className="flex items-center">
            <img src={`${process.env.PUBLIC_URL}/assets/images/bed.svg`} alt="Beds" className="w-4 h-4" />
            <p className="ml-1 text-xs">{data.numberOfBedrooms} Beds</p>
          </div>
          <div className="flex items-center">
            <img src={`${process.env.PUBLIC_URL}/assets/images/bathtub.svg`} alt="Baths" className="w-4 h-4" />
            <p className="ml-1 text-xs">{data.numberOfBathrooms} Bath</p>
          </div>
          <div className="flex items-center">
            <img src={`${process.env.PUBLIC_URL}/assets/images/length.svg`} alt="Size" className="w-4 h-4" />
            <p className="ml-1 text-xs">{data.squareFootage || '--'} sqm</p>
          </div>
        </div>
        <p className="text-xs text-gray-500 mt-2">Listed by {data.ownerName.length > 15 ? `${data.ownerName.slice(0, 12)}...` : data.ownerName}</p>
      </div>
    </div>
  );
};

export default HouseForSaleCard;


