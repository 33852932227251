

import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import LoginPopover from './LoginPopover';
import RegisterPopover from './RegisterPopover'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { useAuth } from '../context/authContext';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null); 
  const target = useRef(null); 

  const { showPopover, setShowPopover,isLoggedIn, logout,  profile } = useAuth();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handlePopoverClose = (type) => {
    setShowPopover(type || null);
  };

  const handleLogout = async () => {
      await logout();
      setShowDropdown(false);
      navigate('/home'); 
  };


  return (
    <header className="w-full md:w-[90%]  mx-auto my-1 flex items-center justify-between p-2 bg-transparent">
      <div className="flex items-center space-x-2" onClick={()=> navigate('/home')}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
          alt="Logo"
          onClick={() =>  navigate('/post')}
          className="w-32 h-8 md:w-40 md:h-10"
        />
      </div>

      {/* Desktop and Tablet View */}
      <div className="hidden md:flex flex-grow items-center">
        <nav style={{fontWeight: 500}} className="flex-grow flex text-20 justify-center space-x-7 font-roboto font-500">
          {/* <NavLink
            to="/home"
            className={({ isActive }) =>
              `relative ${isActive ? 'border-b-2 border-yellow-500' : ''}`}
            style={{ fontSize: '15px', lineHeight: '20px' }}
          >
            HOME
          </NavLink>
          <NavLink
            to="/subscribe"
            className={({ isActive }) =>
              `relative ${isActive ? 'border-b-2 border-yellow-500' : ''}`}
            style={{ fontSize: '15px', lineHeight: '20px' }}
          >
            SUBSCRIPTION
          </NavLink>
          <NavLink
            to="/post"
            className={({ isActive }) =>
              `relative ${isActive ? 'border-b-2 border-yellow-500' : ''}`}
            style={{ fontSize: '15px', lineHeight: '20px' }}
          >
            POST
          </NavLink> */}
        </nav>
        <button
              onClick={() =>  navigate('/posts')}
              className="bg-yellow-500 mr-8 text-white py-1 px-5 rounded-md"
            >
              POSTS
        </button>
        {isLoggedIn ? (
        <div ref={dropdownRef} className="relative flex items-center space-x-2 cursor-pointer">
        {profile.username ? (
          <div
            className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-500 text-white text-xl font-semibold"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {profile.username.charAt(0).toUpperCase()}
          </div>
        ) : (
          <img
            src={profile.avatar || `${process.env.PUBLIC_URL}/assets/images/user.svg`}
            alt="Profile Avatar"
            className="w-10 h-10 rounded-full bg-gray-500 p-1"
            onClick={() => setShowDropdown(!showDropdown)}
          />
        )}
        <FontAwesomeIcon icon={faCaretDown} onClick={() => setShowDropdown(!showDropdown)} />

        {showDropdown && (
          <div className="absolute right-0 mt-36 w-48 bg-white rounded-md shadow-lg py-2">
            <div className="flex justify-end pr-2">
              <FontAwesomeIcon 
                icon={faTimes} 
                className="text-gray-500 cursor-pointer" 
                onClick={() => setShowDropdown(false)} 
              />
            </div>
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={() => {
                console.log("Profile button clicked");
                navigate('/profile');
                setShowDropdown(false);
              }}
            >
              Profile
            </button>
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )}
      </div>

        ) : (
          <div className="flex space-x-4">
            <button
              onClick={() =>  navigate('/login')}
              className="border border-yellow-500 text-yellow-500 py-1 px-5 rounded-md bg-transparent"
            >
              SIGN IN
            </button>
            {/* <button
              onClick={() => setShowPopover('register')}
              className="bg-yellow-500 text-white py-1 px-5 rounded-md"
            >
              REGISTER
            </button> */}
          </div>
        )}
      </div>

      {/* Mobile View */}
      <div className="md:hidden flex items-center w-full justify-between">
        {isLoggedIn ? (
          <div ref={dropdownRef} className="flex w-full justify-center pl-22">
            <img
              src={profile.avatar || `${process.env.PUBLIC_URL}/assets/images/user.svg`}
              alt="Profile Avatar"
              className="w-6 h-6 rounded-full ml-20"
              onClick={() => setShowDropdown(!showDropdown)}
            />
            {showDropdown && (
              <div className="absolute right-0 mt-8 w-40 bg-white rounded-md shadow-lg py-2">
                <div className="flex justify-end pr-2">
                  <FontAwesomeIcon 
                    icon={faTimes} 
                    className="text-gray-500 cursor-pointer" 
                    onClick={() => setShowDropdown(false)} 
                  />
                </div>
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={() => {
                    console.log("Profile button clicked");
                    navigate('/profile');
                    setShowDropdown(false);
                  }}
                >
                  Profile
                </button>
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex w-full justify-center pl-24">
            <button
              onClick={() =>  navigate('/login')}
              className="border border-yellow-500 text-sm text-yellow-500 py-1 px-5 rounded-md bg-transparent"
            >
              SIGN IN
            </button>
          </div>
        )}
        <button onClick={toggleMenu} className="text-yellow-500 relative mr-2">
          <FontAwesomeIcon icon={faBars} className="text-2xl" />
          {isMenuOpen && (
            <div className="absolute top-full right-0 bg-white shadow-lg rounded-md w-40 py-2 flex flex-col items-center space-y-2">
                <NavLink
                to="/faq"
                className="text-gray-800"
                onClick={() =>  navigate('/register')}
              >
                SIGN UP
              </NavLink>
              <NavLink
                to="/home"
                className="text-gray-800"
                onClick={() => setIsMenuOpen(false)}
              >
                HOME
              </NavLink>

              <NavLink
                to="/subscribe"
                className="text-gray-800"
                onClick={() => setIsMenuOpen(false)}
              >
               SUBSCRIPTION
              </NavLink>
              <NavLink
                to="/post"
                className="text-gray-800"
                onClick={() => setIsMenuOpen(false)}
              >
                POST
              </NavLink>

            </div>
          )}
        </button>
      </div>

      {/* Popovers */}
      {showPopover === 'login' && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <LoginPopover onClose={() => handlePopoverClose(null)} />
          {/* <LoginPopover
            show={showPopover}
            target={target.current}
            onClose={() => setShowPopover(false)}
          /> */}
        </div>
      )}
      {showPopover === 'register' && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <RegisterPopover onClose={() => handlePopoverClose(null)} />
        </div>
      )}
    </header>
  );
};

export default Header;
