import React, { useState } from 'react';
import { useAuth } from '../../context/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';

const LoginPage = ({ onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const { login, setShowPopover } = useAuth(); 
  const navigate = useNavigate()
  const location = useLocation()

  const validatePhoneNumber = (phone) => {
    const regex = /^0\d{9}$/; 
    return regex.test(phone);
  };

//   const handleLogin = async () => {
//     if (!phoneNumber) {
//       setError('Phone number is required');
//       return;
//     }
//     if (!validatePhoneNumber(phoneNumber)) {
//       setError('Phone number must be in the format 0742551573');
//       return;
//     }
//     try {
//       await login(phoneNumber); 
//       navigate('/home')
//     } catch (err) {
//         console.log(err);
//       setError('Failed to login. Please try again.');
//     }
//   };

const handleLogin = async () => {
    if (!phoneNumber) {
      setError('Phone number is required');
      return;
    }
    if (!validatePhoneNumber(phoneNumber)) {
      setError('Phone number must be in the format 0742551573');
      return;
    }
    try {
      await login(phoneNumber);
      
      const referrer = location.state?.from?.pathname || '/home';
      
      navigate(referrer); 
    } catch (err) {
      console.log(err);
      setError('Failed to login. Please try again.');
    }
  };

  return (
    <div className="py-20 md:py-40 flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-[640px] md:w-[768px] max-w-full mx-auto">
        <button
          onClick={() => onClose()}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FontAwesomeIcon icon={faTimes} className="text-xl" />
        </button>
        <img
          src="/assets/images/logo.svg"
          alt="Logo"
          className="w-48 h-12 mb-6 mx-auto"
        />
        <h2 className="text-2xl font-semibold mb-6 text-center">Login</h2>
        <input
          type="text"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="w-full border border-gray-300 p-3 rounded mb-4"
        />
        {error && <p className="text-red-500 text-sm mb-4 text-center">{error}</p>}
        <button
          onClick={handleLogin}
          className="bg-yellow-500 text-white py-3 px-6 rounded w-full"
        >
          Login
        </button>
        <p className="mt-6 text-center">
          Don't have an account?{' '}
          <button
            onClick={() => setShowPopover('register')}
            className="text-yellow-500"
          >
            Register
          </button>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
