
import React, { useState } from 'react';
import Search from '../../components/Search';
import { useProperties } from '../../context/propertiesContext';
import ApartmentCard from './components/ApartmentCard';




const Appartments = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [sortOrder, setSortOrder] = useState('new');

  const { categories } = useProperties();


  // const filteredProperties = categories
  //   .filter(property =>
  //     property.location.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //     (selectedCategory ? property.type === selectedCategory : true)
  //   )
  //   .sort((a, b) => {
  //     if (sortOrder === 'priceLowHigh') {
  //       return parseInt(a.price.replace('TZS ', '').replace(',', '')) - parseInt(b.price.replace('TZS ', '').replace(',', ''));
  //     } else if (sortOrder === 'priceHighLow') {
  //       return parseInt(b.price.replace('TZS ', '').replace(',', '')) - parseInt(a.price.replace('TZS ', '').replace(',', ''));
  //     }
  //     return 0;
  //   });

  return (
    <div className="bg-gray-100">
      <div className="container w-[95%] mx-auto flex flex-col mx-auto">
        <main className="p-4  items-center">
          <Search placeholder="Search Properties in Tanzania" onSearch={setSearchTerm} />
          
          <h1 className="text-2xl font-semibold mt-10 mb-6 text-center">
            Apartments For Rent
          </h1>
          
          <div className="mb-6 flex justify-between w-full space-x-2">
            <div>
              <select 
                onChange={(e) => setSelectedCategory(e.target.value)} 
                value={selectedCategory}
                className="border rounded-lg p-2"
              >
                <option value="">All Categories</option>
                <option value="Rent">Rent</option>
                <option value="For Sale">For Sale</option>
              </select>
            </div>
            <div>
              <select 
                onChange={(e) => setSortOrder(e.target.value)} 
                value={sortOrder}
                className="border rounded-lg p-2"
              >
                <option value="new">Newest</option>
                <option value="priceLowHigh">Price: Low to High</option>
                <option value="priceHighLow">Price: High to Low</option>
              </select>
            </div>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {categories.Apartment.map(property => (
              <ApartmentCard key={property.location} data={property} />
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Appartments;
