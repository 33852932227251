

import React, { useState } from 'react';
import Search from '../../components/Search';
import Carousel from '../../components/Carousel';
import Banner from './components/Banner';
import { useProperties } from '../../context/propertiesContext';



const Home = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [sortOrder, setSortOrder] = useState('new');

  const { categories } = useProperties();

  const filteredLand = categories.Land
    .filter(property =>
      property?.location?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory ? property?.type === selectedCategory : true)
    )
    .sort((a, b) => {
      if (sortOrder === 'priceLowHigh') {
        return parseInt(a.price.replace('TZS ', '').replace(',', '')) - parseInt(b.price.replace('TZS ', '').replace(',', ''));
      } else if (sortOrder === 'priceHighLow') {
        return parseInt(b.price.replace('TZS ', '').replace(',', '')) - parseInt(a.price.replace('TZS ', '').replace(',', ''));
      }
      return 0;
    });
    console.log('Filtered Land',filteredLand);
    const filteredRent = categories?.Rent
    .filter(property =>
      property?.location?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory ? property?.type === selectedCategory : true)
    )
    .sort((a, b) => {
      if (sortOrder === 'priceLowHigh') {
        return parseInt(a.price.replace('TZS ', '').replace(',', '')) - parseInt(b.price.replace('TZS ', '').replace(',', ''));
      } else if (sortOrder === 'priceHighLow') {
        return parseInt(b.price.replace('TZS ', '').replace(',', '')) - parseInt(a.price.replace('TZS ', '').replace(',', ''));
      }
      return 0;
    });

    const filteredApartment = categories.Apartment
    .filter(property =>
      property?.location?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory ? property?.type === selectedCategory : true)
    )
    .sort((a, b) => {
      if (sortOrder === 'priceLowHigh') {
        return parseInt(a.price.replace('TZS ', '').replace(',', '')) - parseInt(b.price.replace('TZS ', '').replace(',', ''));
      } else if (sortOrder === 'priceHighLow') {
        return parseInt(b.price.replace('TZS ', '').replace(',', '')) - parseInt(a.price.replace('TZS ', '').replace(',', ''));
      }
      return 0;
    });

    const filteredHouseForSale = categories?.HouseForSale
    .filter(property =>
      property?.location?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory ? property?.type === selectedCategory : true)
    )
    .sort((a, b) => {
      if (sortOrder === 'priceLowHigh') {
        return parseInt(a.price.replace('TZS ', '').replace(',', '')) - parseInt(b.price.replace('TZS ', '').replace(',', ''));
      } else if (sortOrder === 'priceHighLow') {
        return parseInt(b.price.replace('TZS ', '').replace(',', '')) - parseInt(a.price.replace('TZS ', '').replace(',', ''));
      }
      return 0;
    });
    

  return (
    <div className='bg-gray-100'>
      <main className="p-4">
        <Search placeholder="Search Properties in Tanzania" onSearch={setSearchTerm} />
        <Banner />
        <div>
          <div className='mb-6'>
          <Carousel
            data={categories.Rent}
            type="Rent"
            route='/lease'
            title="Nyumba za Kupanga"
            desc = "Nyumba za kupanga 200+ zinapatikana"
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setSortOrder={setSortOrder}
          />
          </div>
          <div className='mb-6'> 
          <Carousel
            data={categories.Apartment}
            title="Appartments"
            type="Apartment"
            route='/apartment'
            desc = "Nyumba 300+ zinapatikana"
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setSortOrder={setSortOrder}
          />
          </div>
          <div className='mb-6'> 
          <Carousel
            data={categories.Land}
            title="Viwanja"
            type="Land"
            route='/land'
            desc = "Viwanja 500+ vinapatikana"
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setSortOrder={setSortOrder}
          />
          </div>
          <div className='mb-6'> 
          <Carousel
            data={categories.HouseForSale}
            title="Nyumba za Kuuza"
            type="HouseForSale"
            route='/house-for-sale'
            desc = "Nyumba 500+ zinapatikana"
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setSortOrder={setSortOrder}
          />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
