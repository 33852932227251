

import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const PaymentPage = () => {
  const { state } = useLocation();
  const { plan } = state;

  const [paymentMethod, setPaymentMethod] = useState("Mobile Payment");
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="bg-gray-100 w-full">
      <div className="container mx-auto justify-center p-6 md:p-12">
        <button 
          onClick={handleBackClick}
          className="flex items-center text-gray-500 hover:text-gray-900 hover:ml-4 mb-6 transition duration-1200"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 transition duration-1200" />
          Back
        </button>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">{plan.duration} Plan</h2>
            <p className="mb-6">Billed to:</p>
            <input 
              type="text" 
              value="John Mirembe" 
              readOnly 
              className="w-full p-2 mb-6 border rounded-lg"
            />
            <p className="mb-6 font-semibold">Choose Payment Method</p>
            <div className="flex justify-between mb-6">
              {/* <button 
                className={`w-1/3 p-4 rounded-lg focus:outline-none ${paymentMethod === "Credit Card" ? "bg-yellow-500 text-white" : "border"}`}
                onClick={() => setPaymentMethod("Credit Card")}
              >
                Credit Card
              </button>
              <button 
                className={`w-1/3 p-4 rounded-lg ${paymentMethod === "Bank Transfer" ? "bg-yellow-500 text-white" : "border"}`}
                onClick={() => setPaymentMethod("Bank Transfer")}
              >
                Bank Transfer
              </button> */}
              <button 
                className={`w-1/3 p-4 rounded-lg ${paymentMethod === "Mobile Payment" ? "bg-yellow-500 text-white" : "border"}`}
                onClick={() => setPaymentMethod("Mobile Payment")}
              >
                Mobile Payment
              </button>
            </div>

            {paymentMethod === "Credit Card" && (
              <>
                <input 
                  type="text" 
                  placeholder="Card Number" 
                  className="w-full p-2 mb-6 border rounded-lg"
                />
                <div className="flex justify-between mb-6">
                  <input 
                    type="text" 
                    placeholder="Card Expiry Date" 
                    className="w-1/2 p-2 mr-2 border rounded-lg"
                  />
                  <input 
                    type="text" 
                    placeholder="CVV" 
                    className="w-1/2 p-2 ml-2 border rounded-lg"
                  />
                </div>
              </>
            )}

            {paymentMethod === "Bank Transfer" && (
              <>
                <input 
                  type="text" 
                  placeholder="Bank Name" 
                  className="w-full p-2 mb-6 border rounded-lg"
                />
                <input 
                  type="text" 
                  placeholder="Account Number" 
                  className="w-full p-2 mb-6 border rounded-lg"
                />
                <input 
                  type="text" 
                  placeholder="Bank Branch" 
                  className="w-full p-2 mb-6 border rounded-lg"
                />
              </>
            )}

            {paymentMethod === "Mobile Payment" && (
              <>
                <input 
                  type="text" 
                  placeholder="Mobile Number" 
                  className="w-full p-2 mb-6 border rounded-lg"
                />
                <input 
                  type="text" 
                  placeholder="Mobile Payment Provider" 
                  className="w-full p-2 mb-6 border rounded-lg"
                />
              </>
            )}

            <input 
              type="text" 
              placeholder="Billing Address" 
              className="w-full p-2 mb-6 border rounded-lg"
            />
            <input 
              type="text" 
              placeholder="Zip Code" 
              className="w-full p-2 mb-6 border rounded-lg"
            />

            <div className="flex justify-between">
              <button className="w-1/3 p-3 border rounded-lg hover:bg-gray-100">Cancel</button>
              <button className="w-1/3 bg-yellow-500 p-3 text-white rounded-lg hover:bg-yellow-600">
                Submit
              </button>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">{plan.duration} Plan</h2>
            <div className="border-t border-b py-4">
              <p className="flex justify-between">
                <span>Subtotal</span> 
                <span>{plan.price}</span>
              </p>
              <p className="flex justify-between">
                <span>Discount</span> 
                <span>0%</span>
              </p>
              <p className="flex justify-between font-bold">
                <span>Total</span> 
                <span>{plan.price}</span>
              </p>
            </div>
            <p className="font-semibold my-6">Upgrade Plans to Other Plans</p>
            <div className="flex justify-between">
              <Link to="/plan/1%20Day" state={{ plan: { duration: "1 Days", price: "$1" } }}>
                <button className={`w-[150px] border p-4 rounded-lg hover:bg-gray-100 ${plan.duration === "1 Days" ? "bg-yellow-500 text-white" : ""}`}>
                  1 Days
                </button>
              </Link>
              <Link to="/plan/7%20Days" state={{ plan: { duration: "7 Days", price: "$10" } }}>
                <button className={`w-[150px] border p-4 rounded-lg hover:bg-gray-100 ${plan.duration === "7 Days" ? "bg-yellow-500 text-white" : ""}`}>
                  7 Days
                </button>
              </Link>
              <Link to="/plan/14%20Days" state={{ plan: { duration: "14 Days", price: "$20" } }}>
                <button className={`w-[150px] border p-4 rounded-lg hover:bg-gray-100 ${plan.duration === "14 Days" ? "bg-yellow-500 text-white" : ""}`}>
                  14 Days
                </button>
              </Link>
              <Link to="/plan/30%20Days" state={{ plan: { duration: "30 Days", price: "$30" } }}>
                <button className={`w-[150px] border p-4 rounded-lg hover:bg-gray-100 ${plan.duration === "30 Days" ? "bg-yellow-500 text-white" : ""}`}>
                  30 Days
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
