

import React, { useState, useEffect, useRef } from 'react';
import apiClient from '../../api/apiClient';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router';


const PropertyForm = () => {
  const [propertyType, setPropertyType] = useState(''); 
  const [step, setStep] = useState(1); 
  const [formData, setFormData] = useState({});
  const [propertyId, setPropertyId] = useState(null); 
  const { profile } = useAuth();
  const [imageFormData, setImageFormData] = useState(new FormData());
  const navigate = useNavigate();
  const fileInputRef = useRef(null);


  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [streets, setStreets] = useState([]);

  const [regionId, setRegionId] = useState('');
  const [districtId, setDistrictId] = useState('');
  const [wardId, setWardId] = useState('');
  const [streetId, setStreetId] = useState('');



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitStep1 = async (e) => {
    e.preventDefault();
  
    let updatedFormData = { ...formData }; 
  
    if (propertyType === 'lease') {
      updatedFormData = { 
        ...updatedFormData, 
        type: 'Rent', 
        publishedBy: profile?.sub 
      };
    }
    
    if (propertyType === 'land') {
      updatedFormData = { 
        ...updatedFormData, 
        type: 'Land', 
        publishedBy: profile?.sub 
      };
    }
    if (propertyType === 'apartment') {
      updatedFormData = { 
        ...updatedFormData, 
        type: 'Apartment', 
        publishedBy: profile?.sub 
      };
    }
    if (propertyType === 'house-for-sale') {
      updatedFormData = { 
        ...updatedFormData, 
        type: 'HouseForSale', 
        publishedBy: profile?.sub 
      };
    }

    try {
      const response = await apiClient.post(`/properties/${propertyType}`, updatedFormData);
      const newPropertyId = response.data.id;
      setPropertyId(newPropertyId);
      setFormData({});
      setStep(2);
    } catch (error) {
      console.error('Error submitting step 1:', error);
    }
   
  };
  

  const handleSubmitStep2 = async (e) => {
    e.preventDefault();
    
    try {
      const updatedFormData = {
        ...formData,
        regionId,
        districtId,
        wardId,
        streetId,
      };
      setStep(3);
      await apiClient.post(`/properties/${propertyId}/address`, updatedFormData);
      setFormData({});
      setRegionId('');
      setDistrictId('');
      setWardId('');
      setStreetId('');
    } catch (error) {
      console.error('Error submitting step 2:', error);
    }
  };


const handleImageChange = (e, id, fieldName) => {
  const files = Array.from(e.target.files);
  console.log('Selected Files:', files); 

  const imageFormDataRaw = new FormData();

  files.forEach((file) => {
      imageFormDataRaw.append('files', file); 
  });
  imageFormDataRaw.append('category', 'image');

  console.log('FormData Entries Before Save:');
  for (let pair of imageFormDataRaw.entries()) {
      console.log(`${pair[0]}:`, pair[1]);
  }

  setImageFormData(imageFormDataRaw); 
};

const saveImages = async () => {
  console.log('FormData Entries at Save:');
  if (imageFormData instanceof FormData) {
    for (let pair of imageFormData.entries()) {
        console.log(`${pair[0]}:`, pair[1]);
    }
  } else {
    console.error('imageFormData is not a FormData object');
    return;
  }

  try {
    const response = await apiClient.post(`/properties/${propertyId}/media`, imageFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Response:', response);
    setImageFormData(new FormData()); 
    setPropertyType('');
    setPropertyId(null);
    fileInputRef.current.value = ''; 
    navigate('/posts');
  } catch (error) {
    console.error('Error uploading files:', error);
    fileInputRef.current.value = ''; 
  }
};

  
  


  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await apiClient.get('/locations/regions');
        setRegions(response.data);
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    fetchRegions();
  }, []);

  useEffect(() => {
    const fetchDistricts = async () => {
      if (!regionId) return;

      try {
        const response = await apiClient.get(`/locations/regions/${regionId}`);
        setDistricts(response.data.districts);
        setWards([]); 
        setStreets([]); 
      } catch (error) {
        console.error('Error fetching districts:', error);
      }
    };

    fetchDistricts();
  }, [regionId]);

  useEffect(() => {
    const fetchWards = async () => {
      if (!districtId) return;

      try {
        const response = await apiClient.get(`/locations/districts/${districtId}`);
        setWards(response.data.wards);
        setStreets([]); 
      } catch (error) {
        console.error('Error fetching wards:', error);
      }
    };

    fetchWards();
  }, [districtId]);

  useEffect(() => {
    const fetchStreets = async () => {
      if (!wardId) return;

      try {
        const response = await apiClient.get(`/locations/wards/${wardId}`);
        setStreets(response.data.streets);
      } catch (error) {
        console.error('Error fetching streets:', error);
      }
    };

    fetchStreets();
  }, [wardId]);


const renderFormFields = () => {
    if (step === 1) {
      switch (propertyType) {
        case 'lease':
          return (
            <>
            <h2 className="text-2xl font-bold mb-6">Lease Details</h2>
            <div className="flex flex-col lg:flex-row">
      
            <div className="lg:w-1/2 p-8">
              <form>
                <p className="font-medium text-lg mb-1">Title</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="text"
                  name="title"
                  placeholder="Title"
                  onChange={handleInputChange}
                  required
                />
      
                <p className="font-medium text-lg mb-1">Description</p>
                <textarea
                  className="w-full p-2 border rounded mb-4"
                  name="description"
                  placeholder="Description"
                  onChange={handleInputChange}
                  required
                />
      
                <p className="font-medium text-lg mb-1">Owner Name</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="text"
                  name="ownerName"
                  placeholder="Owner Name"
                  onChange={handleInputChange}
                  required
                />
      
                <p className="font-medium text-lg mb-1">Owner Phone Number</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="text"
                  name="ownerPhoneNumber"
                  placeholder="Owner Phone Number"
                  onChange={handleInputChange}
                  required
                />
            
              
              </form>
            </div>
            <div className="lg:w-1/2 pt-10 p-8">
              <form>
                <p className="font-medium text-lg mb-1">Number of Bedrooms</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="number"
                  name="numberOfBedrooms"
                  placeholder="Number of Bedrooms"
                  onChange={handleInputChange}
                  required
                />
      
                <p className="font-medium text-lg mb-1">Number of Bathrooms</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="number"
                  name="numberOfBathrooms"
                  placeholder="Number of Bathrooms"
                  onChange={handleInputChange}
                  required
                />
      
                <p className="font-medium text-lg mb-1">Lease Term</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="number"
                  name="leaseTerm"
                  placeholder="Lease Term"
                  onChange={handleInputChange}
                  required
                />
      
                <p className="font-medium text-lg mb-1">Lease Price</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="number"
                  name="leasePrice"
                  placeholder="Lease Price"
                  onChange={handleInputChange}
                  required
                />
              </form>
            </div>
          </div>
          </>
          );
        case 'land':
          return (
            <>
                <h2 className="text-2xl font-bold mb-6">Land Details</h2>
              <div className="flex flex-col lg:flex-row">
          
                <div className="lg:w-1/2 p-8">
                  <form>
                  <p className="font-medium text-lg mb-1">Title</p>
              <input className="w-full p-2 border rounded mb-2" type="text" name="title" placeholder="Title" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Description</p>
              <textarea className="w-full p-2 border rounded mb-2" name="description" placeholder="Description" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Owner Name</p>
              <input className="w-full p-2 border rounded mb-2" type="text" name="ownerName" placeholder="Owner Name" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Owner Phone Number</p>
              <input className="w-full p-2 border rounded mb-2" type="text" name="ownerPhoneNumber" placeholder="Owner Phone Number" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Published By</p>
              <input className="w-full p-2 border rounded mb-2" type="text" name="publishedBy" placeholder="Published By" onChange={handleInputChange} required />
  
              </form>
                </div>
              <div className="lg:w-1/2 p-8">
                <form>
              <p className="font-medium text-lg mb-1">Lot Length</p>
              <input className="w-full p-2 border rounded mb-2" type="number" name="lotLength" placeholder="Lot Length" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Lot Width</p>
              <input className="w-full p-2 border rounded mb-2" type="number" name="lotWidth" placeholder="Lot Width" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Lot Status</p>
              <input className="w-full p-2 border rounded mb-2" type="text" name="lotStatus" placeholder="Lot Status" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Lot Price</p>
              <input className="w-full p-2 border rounded mb-2" type="number" name="lotPrice" placeholder="Lot Price" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Distance From Main Road</p>
              <input className="w-full p-2 border rounded mb-2" type="text" name="distanceFromMainRoad" placeholder="Distance From Main Road" onChange={handleInputChange} required />
  
              <label className="flex items-center mb-2">
                <input className="mr-2" type="checkbox" name="isWaterServiceAvailable" onChange={(e) => handleInputChange({ target: { name: 'isWaterServiceAvailable', value: e.target.checked } })} />
                Water Service Available
              </label>
  
              <label className="flex items-center mb-2">
                <input className="mr-2" type="checkbox" name="isElectricityServiceAvailable" onChange={(e) => handleInputChange({ target: { name: 'isElectricityServiceAvailable', value: e.target.checked } })} />
                Electricity Service Available
              </label>
                  </form>
                </div>
              </div>
            </>
          );
        case 'apartment':
          return (
             <>
             <h2 className="text-2xl font-bold mb-6">Apartment Details</h2>
           <div className="flex flex-col lg:flex-row">
       
             <div className="lg:w-1/2 p-8">
               <form>
               <p className="font-medium text-lg mb-1">Title</p>
              <input className="w-full p-2 border rounded mb-2" type="text" name="title" placeholder="Title" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Description</p>
              <textarea className="w-full p-2 border rounded mb-2" name="description" placeholder="Description" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Owner Name</p>
              <input className="w-full p-2 border rounded mb-2" type="text" name="ownerName" placeholder="Owner Name" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Owner Phone Number</p>
              <input className="w-full p-2 border rounded mb-2" type="text" name="ownerPhoneNumber" placeholder="Owner Phone Number" onChange={handleInputChange} required />
             
              <p className="font-medium text-lg mb-1">Number of Bedrooms</p>
              <input className="w-full p-2 border rounded mb-2" type="number" name="numberOfBedrooms" placeholder="Number of Bedrooms" onChange={handleInputChange} required />
  
               </form>
             </div>
             <div className="lg:w-1/2 p-8">
              <form>
              <p className="font-medium text-lg mb-1">Number of Bathrooms</p>
              <input className="w-full p-2 border rounded mb-2" type="number" name="numberOfBathrooms" placeholder="Number of Bathrooms" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Apartment Price</p>
              <input className="w-full p-2 border rounded mb-2" type="number" name="appartmentPrice" placeholder="Apartment Price" onChange={handleInputChange} required />
  
              <p className="font-medium text-lg mb-1">Apartment Price Term</p>
              <input className="w-full p-2 border rounded mb-2" type="text" name="appartmentPriceTerm" placeholder="Apartment Price Term" onChange={handleInputChange} required />
               </form>
             </div>
           </div>
         </>
          );
          case 'house-for-sale':
            return (
               <>
               <h2 className="text-2xl font-bold mb-6">House Details</h2>
             <div className="flex flex-col lg:flex-row">
         
               <div className="lg:w-1/2 p-8">
                 <form>
                 <p className="font-medium text-lg mb-1">Title</p>
                <input className="w-full p-2 border rounded mb-2" type="text" name="title" placeholder="Title" onChange={handleInputChange} required />
    
                <p className="font-medium text-lg mb-1">Description</p>
                <textarea className="w-full p-2 border rounded mb-2" name="description" placeholder="Description" onChange={handleInputChange} required />
    
                <p className="font-medium text-lg mb-1">Owner Name</p>
                <input className="w-full p-2 border rounded mb-2" type="text" name="ownerName" placeholder="Owner Name" onChange={handleInputChange} required />
    
                <p className="font-medium text-lg mb-1">Owner Phone Number</p>
                <input className="w-full p-2 border rounded mb-2" type="text" name="ownerPhoneNumber" placeholder="Owner Phone Number" onChange={handleInputChange} required />

                 </form>
               </div>
               <div className="lg:w-1/2 p-8">
                 <form>
                <p className="font-medium text-lg mb-1">Number of Bedrooms</p>
                <input className="w-full p-2 border rounded mb-2" type="number" name="numberOfBedrooms" placeholder="Number of Bedrooms" onChange={handleInputChange} required />
    
                <p className="font-medium text-lg mb-1">Number of Bathrooms</p>
                <input className="w-full p-2 border rounded mb-2" type="number" name="numberOfBathrooms" placeholder="Number of Bathrooms" onChange={handleInputChange} required />
    
                <p className="font-medium text-lg mb-1">House Price</p>
                <input className="w-full p-2 border rounded mb-2" type="number" name="housePrice" placeholder="House Price" onChange={handleInputChange} required />
    
                <p className="font-medium text-lg mb-1">Distance from main road</p>
                <input className="w-full p-2 border rounded mb-2" type="text" name="distanceFromMainRoad" placeholder="Distance from main road" onChange={handleInputChange} required />
                <label className="flex items-center mb-2">
                <input className="mr-2" type="checkbox" name="isWaterServiceAvailable" onChange={(e) => handleInputChange({ target: { name: 'isWaterServiceAvailable', value: e.target.checked } })} />
                Water Service Available
              </label>
  
              <label className="flex items-center mb-2">
                <input className="mr-2" type="checkbox" name="isElectricityServiceAvailable" onChange={(e) => handleInputChange({ target: { name: 'isElectricityServiceAvailable', value: e.target.checked } })} />
                Electricity Service Available
              </label>
                 </form>
               </div>
             </div>
           </>
            );
        default:
          return null;
      }
    }
  
    if (step === 2) {
      return (
<>
        <p className="font-medium text-lg mb-1">House Number</p>
        <input
          className="w-full p-2 border rounded mb-4"
          type="text"
          name="houseNumber"
          placeholder="Title"
          onChange={handleInputChange}
        />

      <p className="font-medium text-lg mb-1">Apartment Number</p>
        <input
          className="w-full p-2 border rounded mb-4"
          type="text"
          name="apartmentNumber"
          placeholder="Title"
          onChange={handleInputChange}
        />
        <p className="font-medium text-lg mb-1">Apartment Number</p>
        <input
          className="w-full p-2 border rounded mb-4"
          type="text"
          name="plotNumber"
          placeholder="Title"
          onChange={handleInputChange}
        />

      <label htmlFor="region" className="block mb-2 text-sm font-medium text-gray-700">
        Region
      </label>
      <select
        id="region"
        value={regionId}
        onChange={(e) => setRegionId(e.target.value)}
        className="w-full p-2 border rounded"
      >
        <option value="" disabled>Select Region</option>
        {regions.map((region) => (
          <option key={region.id} value={region.id}>
            {region.name}
          </option>
        ))}
      </select>

      <label htmlFor="district" className="block mt-4 mb-2 text-sm font-medium text-gray-700">
        District
      </label>
      <select
        id="district"
        value={districtId}
        onChange={(e) => setDistrictId(e.target.value)}
        className="w-full p-2 border rounded"
        disabled={!regionId}
      >
        <option value="" disabled>Select District</option>
        {districts.map((district) => (
          <option key={district.id} value={district.id}>
            {district.name}
          </option>
        ))}
      </select>

      <label htmlFor="ward" className="block mt-4 mb-2 text-sm font-medium text-gray-700">
        Ward
      </label>
      <select
        id="ward"
        value={wardId}
        onChange={(e) => setWardId(e.target.value)}
        className="w-full p-2 border rounded"
        disabled={!districtId}
      >
        <option value="" disabled>Select Ward</option>
        {wards.map((ward) => (
          <option key={ward.id} value={ward.id}>
            {ward.name}
          </option>
        ))}
      </select>

      <label htmlFor="street" className="block mt-4 mb-2 text-sm font-medium text-gray-700">
        Street
      </label>
      <select
        id="street"
        value={streetId}
        onChange={(e) => setStreetId(e.target.value)}
        className="w-full p-2 border rounded"
        disabled={!wardId}
      >
        <option value="" disabled>Select Street</option>
        {streets.map((street) => (
          <option key={street.id} value={street.id}>
            {street.name}
          </option>
        ))}
      </select>
</>
      );
    }
  };

  if (step === 3) {
    switch (propertyType) {
      case 'lease': {
        return (
          <>  
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Inside</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  name="files"
                  multiple
                  accept="image/*"
                  placeholder="outside"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Outside</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="outside"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Kitchen</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="kitchen"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Toilet</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="toilet"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
          </>
        );
      }

      case 'land': {
        return <>
                  <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Inside</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  name="files"
                  multiple
                  accept="image/*"
                  placeholder="outside"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Outside</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="outside"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Kitchen</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="kitchen"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Toilet</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="toilet"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
           </>;
      }

      case 'apartment': {
        return <>
          <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Inside</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  name="files"
                  multiple
                  accept="image/*"
                  placeholder="outside"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Outside</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="outside"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Kitchen</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="kitchen"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Toilet</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="toilet"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
         </>;
      }

      case 'house-for-sale': {
        return <>
          <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Inside</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  name="files"
                  multiple
                  accept="image/*"
                  placeholder="outside"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Outside</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="outside"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Kitchen</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="kitchen"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
            
            <div className='flex'>
              <div className='flex flex-col'>
                <p className="font-medium text-lg mb-1">Toilet</p>
                <input
                  className="w-full p-2 border rounded mb-4"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  name="files"
                  multiple
                  placeholder="toilet"
                  onChange={(e) => handleImageChange(e, 5, 'files')}
                />
              </div>
              <button onClick={saveImages}>Save</button>
            </div>
        </>;
      }

      default:
        return null;
    }
  }

  

  return (
    <div className="max-w-[80%] mx-auto my-8 p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">Property Form</h2>
      <form onSubmit={step === 1 ? handleSubmitStep1 : step === 2 ? handleSubmitStep2 : saveImages}>
        {step === 1 && (
          <div className="mb-4 w-1/2 mx-auto">
            <label htmlFor="propertyType" className="block mb-2 text-sm font-medium text-gray-700">
              Select Property Type:
            </label>
            <select
              id="propertyType"
              value={propertyType}
              onChange={(e) => setPropertyType(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="" disabled>Select property type</option>
              <option value="lease">Lease</option>
              <option value="land">Land</option>
              <option value="apartment">Apartment</option>
              <option value="house-for-sale">House for Sale</option>
            </select>
          </div>
        )}
        {renderFormFields()}
        <div className="text-center mt-4">
          <button type="submit" className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-700">
          {step === 1 ? 'Next' : step === 2 ? 'Next' : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PropertyForm;
