import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Router/Layout/Layout';
import ClientRoutes from './Router/clientRoutes';
import { AuthProvider } from './context/authContext';
import { PropertiesProvider } from './context/propertiesContext';


function App() {
  return (
    <div className="App">
    <AuthProvider>
      <PropertiesProvider>
          <Layout>
            <ClientRoutes />
          </Layout>
      </PropertiesProvider>
    </AuthProvider>
    </div>
  );
}

export default App;
