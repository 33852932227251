

import React from 'react';

const Filter = ({ selectedCategory, onSelectCategory, onSort }) => {
   // eslint-disable-next-line 
  const categories = [
    { label: 'Nyumba za Kupanga', count: 200 },
    { label: 'Appartments', count: 100 },
    { label: 'Viwanja', count: 100 },
  ];

  return (
    <div className="my-4">
      <div className="flex justify-center md:justify-between lg:justify-between xl:justify-between items-center">
        <div>
          <select className="p-2 border border-gray-300 rounded-md" onChange={(e) => onSort(e.target.value)}>
            <option value="new">Sort by: New Listing</option>
            <option value="priceLowHigh">Sort by: Price Low to High</option>
            <option value="priceHighLow">Sort by: Price High to Low</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Filter;
