
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faHeart, faMapMarkerAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'; 

const DetailedCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data;

  const handleBackClick = () => {
    navigate(-1); 
  };

  return (
    <div className="w-full bg-gray-100 py-8">
      <div className="container mx-auto p-2 md:p-8">
        <button 
          onClick={handleBackClick}
          className="flex ml-6 md:ml-2 lg:ml-2 items-center text-gray-500 hover:text-gray-900 hover:ml-4 mb-6 transition duration-1200"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 transition duration-1200" />
          Back
        </button>
        
        <div className="relative">
          <img
            src={data.image} 
            alt="Property"
            style={{ height: 500 }}
            className="w-full object-cover rounded-lg"
          />
          <div className="absolute top-1/2 left-4 transform -translate-y-1/2">
            <FontAwesomeIcon icon={faAngleLeft} className="text-white text-4xl" />
          </div>
          <div className="absolute top-1/2 right-4 transform -translate-y-1/2">
            <FontAwesomeIcon icon={faAngleRight} className="text-white text-4xl" />
          </div>
        </div>

        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col space-y-6">
            <h2 className="text-3xl font-bold">{data.title || "Title"}</h2>
            <p className="text-lg text-gray-700">{data.description || "Description"}</p>

            <div className="flex flex-col items-start space-y-4">
              <div>
                <FontAwesomeIcon
                  icon={faBook}
                  className="border rounded-full p-3 text-xl hover:text-gray-600 cursor-pointer"
                />
                <FontAwesomeIcon
                  icon={faHeart}
                  className="border rounded-full p-3 text-xl hover:text-red-500 cursor-pointer"
                />
              </div>
              <div className={`flex items-center text-${data.type !== 'Rent' ? 'green' : 'yellow'}-500 text-lg`}>
                <div className={`w-3 h-3 bg-${data.type !== 'Rent' ? 'green' : 'yellow'}-500 rounded-full mr-3`}></div>
                <p className="text-xl">{data.type}</p>
              </div>
            </div>

            <p className="text-xl font-bold mt-4">{data.price}</p>
            <div className="flex items-center text-lg text-gray-500 mt-2">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
              <p>{data.location}</p>
            </div>
          </div>

          <div className="flex flex-col justify-between">
            <div className="space-y-6">
              <div className="flex items-center">
                <img src={`${process.env.PUBLIC_URL}/assets/images/bed.svg`} alt="Beds" className="w-6 h-6" />
                <p className="ml-3 text-lg">{data.beds} Beds</p>
              </div>
              <div className="flex items-center">
                <img src={`${process.env.PUBLIC_URL}/assets/images/bathtub.svg`} alt="Baths" className="w-6 h-6" />
                <p className="ml-3 text-lg">{data.baths} Baths</p>
              </div>
              <div className="flex items-center">
                <img src={`${process.env.PUBLIC_URL}/assets/images/length.svg`} alt="Size" className="w-6 h-6" />
                <p className="ml-3 text-lg">{data.size} sqm</p>
              </div>
              <div className="flex items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/user.png`}
                  alt="Agent"
                  className="w-8 h-8 object-cover rounded-full"
                />
                <p className="ml-2 text-sm">{data.agent}</p>
              </div>
            </div>
            <button className="bg-yellow-500 text-black px-4 py-4 text-md rounded-lg hover:opacity-75 mt-6 md:mt-0">
              View Contacts
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedCard;
