import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const { isLoggedIn, isSubscribed } = useAuth();
const navigate = useNavigate()
const location = useLocation()
  useEffect(() => {
    if (!isLoggedIn) {
      toast.info("You must be logged in");
      navigate('/login', { state: { from: location } });
    }

  }, [isLoggedIn]);

  return isLoggedIn ? element : <Navigate to="/home" />;
};

const SubscribedRoute = ({ element }) => {
    const { isLoggedIn, isSubscribed } = useAuth();
    const navigate = useNavigate()
    const location = useLocation()
  
    useEffect(() => {
      // if (!isLoggedIn) {
      //   toast.info("You must be logged in");
      //   navigate('/login', { state: { from: location } });
      // }
      if(!isSubscribed){
        toast.info("You must be subscribed");
        navigate('/subscribe', { state: { from: location } });
      }
    }, [isLoggedIn]);
  
    return isSubscribed ? element : <Navigate to="/home" />;
  };
  

export { PrivateRoute, SubscribedRoute };
