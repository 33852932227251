
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Lease from '../pages/Lease/Lease';
import Land from '../pages/Land/land';
import Appartments from '../pages/Apartments/Apartments';
import HouseForSale from '../pages/HouseForSale/HouseForSale';
import SubscriptionPage from '../pages/Subscription/Subscription';
import PaymentPage from '../pages/Subscription/Payment';
import { PrivateRoute, SubscribedRoute } from './PrivateRoute';
import DetailedCard from '../components/CardDetails';
import PropertyForm from '../pages/PostProperty/PostProperty';
import LoginPage from '../pages/LoginPage/LoginPage';
import RegisterPage from '../pages/RegisterPage/RegisterPage';
import PostsPage from '../pages/PostProperty/Posts';




const ClientRoutes = () => {
  return (
    <Routes>
       <Route path="/home" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/subscribe" element={<SubscriptionPage />} />
      <Route path="/details/:id" element={<SubscribedRoute element={<DetailedCard />} />} />
      <Route path="/posts" element={<PrivateRoute element={<PostsPage />} />} />
      <Route path="/post" element={<PrivateRoute element={<PropertyForm />} />} />

      <Route path="/plan/:duration" element={<PaymentPage /> } />
      {/* <Route path="/lease" element={<PrivateRoute element={<Lease />} />} /> */}
      {/* <Route path="/land" element={<PrivateRoute element={<Land />} />} /> */}
      {/* <Route path="/apartment" element={<PrivateRoute element={<Appartments />} />} /> */}
      <Route path="/apartment" element={<Appartments />} />
      <Route path="/land" element={<Land />} />
      <Route path="/lease" element={<Lease />} />
      <Route path="/house-for-sale" element={<HouseForSale />} />
      {/* <Route path="/house-for-sale" element={<PrivateRoute element={<HouseForSale />} />} /> */}

      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};

export default ClientRoutes;
