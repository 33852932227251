import apiClient from "../api/apiClient";


 export const getAllProperties = async () => {
    try {
      const response = await apiClient.get('/properties');
      return response.data;
    } catch (error) {
      console.error('Error fetching properties:', error);
      throw error; 
    }
  };
  

  export const getPropertiesByPublisher = async (publisherId) => {
    try {
      const response = await apiClient.get(`/properties/publishers/${publisherId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching properties for publisher ${publisherId}:`, error);
      throw error;
    }
  };
  