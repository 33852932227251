
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-14 mt-auto">
      {/* Desktop and Tablet Design */}
      <div className="container mx-auto px-4 hidden md:block lg:block xl:block">
        <div className="flex justify-center items-center border-b border-gray-600 pb-16 mb-16 space-x-8">
          <Link to="/product" className="hover:underline hover:opacity-75">Product</Link>
          <Link to="/features" className="hover:underline hover:opacity-75">Features</Link>
          <Link to="/resources" className="hover:underline hover:opacity-75">Resources</Link>
          <img src="/assets/images/logo2.svg" alt="Logo" className="w-40 h-10" />
          <Link to="/about" className="hover:underline hover:opacity-75">About</Link>
          <Link to="/blog" className="hover:underline hover:opacity-75">Blog</Link>
          <Link to="/support" className="hover:underline hover:opacity-75">Support</Link>
        </div>
        <div className="text-center">
          <div className="flex justify-center space-x-6 mb-12">
            <Link to="/instagram" className="text-xl p-3 border border-white rounded-full flex items-center justify-center hover:opacity-75">
              <FontAwesomeIcon icon={faInstagram} className="text-2xl" />
            </Link>
            <Link to="/facebook" className="text-xl p-3 border border-white rounded-full flex items-center justify-center hover:opacity-75">
              <FontAwesomeIcon icon={faFacebook} className="text-2xl" />
            </Link>
            <Link to="/twitter" className="text-xl p-3 border border-white rounded-full flex items-center justify-center hover:opacity-75">
              <FontAwesomeIcon icon={faTwitter} className="text-2xl" />
            </Link>
          </div>
          <div className="text-gray-400">
            <div className="flex justify-center space-x-8 mt-2">
              <p>©2024 PN Limited</p>
              <div className="flex space-x-2">
                <Link to="/terms" className="hover:underline hover:opacity-75">Terms</Link>
                <span>|</span>
                <Link to="/feedback" className="hover:underline hover:opacity-75">Feedback</Link>
              </div>
              <Link to="/faq" className="hover:underline hover:opacity-75">FAQ</Link>
            </div>
          </div>
        </div>
      </div>

      {/* Phone Design */}
      <div className="md:hidden grid grid-cols-2 gap-6">
        <div className="flex flex-col items-start ml-12">
          <img src="/assets/images/logo2.svg" alt="Logo" className="w-32 h-8 mb-4" />
          <Link to="/product" className="hover:underline hover:opacity-75 mb-2">Product</Link>
          <Link to="/features" className="hover:underline hover:opacity-75 mb-2">Features</Link>
          <Link to="/resources" className="hover:underline hover:opacity-75 mb-2">Resources</Link>
          <Link to="/about" className="hover:underline hover:opacity-75 mb-2">About</Link>
          <Link to="/blog" className="hover:underline hover:opacity-75 mb-2">Blog</Link>
          <Link to="/support" className="hover:underline hover:opacity-75">Support</Link>
        </div>
        <div className="flex flex-col items-start">
          <div className="flex space-x-4 mb-4">
            <Link to="/instagram" className="text-xl p-2 border border-white rounded-full flex items-center justify-center hover:opacity-75">
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link to="/facebook" className="text-xl p-2 border border-white rounded-full flex items-center justify-center hover:opacity-75">
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link to="/twitter" className="text-xl p-2 border border-white rounded-full flex items-center justify-center hover:opacity-75">
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
          </div>
          <div className="flex space-x-2 mt-24 -mb-2">
            <Link to="/terms" className="hover:underline hover:opacity-75">Terms</Link>
            <span>|</span>
            <Link to="/feedback" className="hover:underline hover:opacity-75">Feedback</Link>
          </div>
          <Link to="/faq" className="hover:underline hover:opacity-75 mb-4">FAQ</Link>
          <p className="text-gray-400">©2024 PN Limited</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
