import apiClient from '../api/apiClient';
import { toast } from 'react-toastify';

async function loginUser(phoneNumber,{setToken}) {

  try {
    const otpResponse = await apiClient.post('/auth/login', { phoneNumber });

    if (otpResponse.status === 200) {
      const otpData = otpResponse.data;
      console.log(otpData.message); 
      toast.info(otpData.message); 
      const otp = otpData.otp;

      const verifyResponse = await apiClient.post('/auth/verify-otp', {
        phoneNumber,
        otp,
      });
    

      if (verifyResponse.status === 200) {
        const verifyData = verifyResponse.data;
        toast.success('Login successful!');
        console.log('Login successful!');
        console.log(verifyResponse)
        setToken(verifyData.access_token);
        localStorage.setItem('token', verifyData.access_token);
       
        localStorage.setItem('refreshToken', verifyData.refresh_token);
        getProfile()

      } else {
        console.error('OTP verification failed:', verifyResponse.data.message);
        toast.error('OTP verification failed:', verifyResponse.data.message);
      }
    } else {
      console.error('Failed to send OTP:', otpResponse.data.message);
      toast.error('Failed to send OTP:', otpResponse.data.message);
    }
  } catch (error) {
    console.error('Error during login:', error);
    toast.error('Error during login:', error);
  }
}


async function refreshAuthToken() {
  const refreshToken = localStorage.getItem('refreshToken');

  if (!refreshToken) {
    console.error('No refresh token found.');
    return;
  }

  try {
    const refreshResponse = await apiClient.post('/auth/refresh-token', {
      refreshToken,
    });

    if (refreshResponse.status === 200) {
      const refreshData = refreshResponse.data;

      localStorage.setItem('token', refreshData.token);
      localStorage.setItem('refreshToken', refreshData.refreshToken);

      console.log('Token refreshed successfully.');
    } else {
      console.error('Failed to refresh token:', refreshResponse.data.message);
    }
  } catch (error) {
    console.error('Error during token refresh:', error);
  }
}

apiClient.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem('token');

    if (!token) {
      token = await refreshAuthToken();
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function registerUser(firstName, lastName, phoneNumber) {
  try {
    const response = await apiClient.post('/auth/signup', {
      firstName,
      lastName,
      phoneNumber
    });

    if (response.status === 200) {
      console.log(response.data.message);
      toast.success(response.data.message);
      return response.data; 
    } else {
      console.error('Registration failed:', response.data.message);
      toast.error('Registration failed:', response.data.message);
    }
  } catch (error) {
    console.error('Error during registration:', error);
    toast.error('Error during registration:', error);
  }
}

async function getProfile() {
  try {
    const response = await apiClient.get('/auth/profile');
    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Failed to fetch profile:', response.data.message);
      // toast.info('Failed to fetch profile:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error fetching profile:', error);
    // toast.info('Error fetching profile:', error);
    return null;
  }
}

async function logoutUser() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  console.log('User logged out successfully.');
  toast.info('User logged out successfully.');
}


export { loginUser, refreshAuthToken, registerUser, getProfile, logoutUser };
